







































































































































































import Vue from "vue";
import moment from "moment";
import apiClient from "@/services/apiClient";
import util from "@/services/util";
import userManager from "@/services/userManager";

export default Vue.extend({
  data() {
    let headers = [
      { text: "Tên khách hàng", value: "name" },
      { text: "Mã khách hàng", value: "codes" },
      { text: "Tháng có đơn", value: "packageMonths" },
      { text: "Nguồn khách", value: "source" },
      { text: "Tiền vận chuyển", value: "total_fee_transport" },
      { text: "Phí ủy thác", value: "total_fee_trust" },
      { text: "Phí dịch vụ", value: "total_fee_service" },
      { text: "Lợi nhuận thanh toán", value: "total_profit" },
      { text: "Tổng lợi nhuận", value: "total_package_profit" },
      { text: "Tổng báo dư", value: "total_surplusage_current" },
      { text: "Còn nợ", value: "total_total_debt" },
    ];
    if (userManager.checkRole(["kd", "tpkd", "gdkd"], true)) {
      headers = headers.filter(
        (h) =>
          ![
            "total_fee_trust",
            "total_fee_service",
            "total_package_profit",
          ].includes(h.value)
      );
    }

    return {
      userManager,
      items: [],
      totalItem: null,
      users: [],
      currentUser: null,
      currentDate: "",
      customerCode: "",
      loading: false,
      filterSource: "",
      headers,
      timeModes: [
        {
          value: "month",
          text: "Tháng",
        },
        {
          value: "year",
          text: "Năm",
        },
      ],
      timeMode: "month",
      availableSourceMap: {
        k: "Khác",
        mkt: "Marketing",
        gt: "Giới thiệu",
        tk: "Tìm kiếm",
      },
    };
  },
  computed: {
    availableSources() {
      const items = [];
      for (const k in this.availableSourceMap) {
        items.push({
          text: this.availableSourceMap[k],
          value: k,
        });
      }
      return items;
    },
    month() {
      return moment(this.currentDate).startOf("month").unix();
    },
  },
  watch: {
    currentUser() {
      this.initialize();
    },
    currentDate() {
      this.initialize();
    },
    timeMode() {
      this.initialize();
    },
  },
  methods: {
    async initialize() {
      if (!this.currentUser || !this.currentDate) {
        return;
      }
      const m = moment(this.currentDate);
      this.loading = true;

      const packageOptions: any = {
        extra: {
          statistics: true,
          truck_statistics: true,
          truck_vn_time: {
            from: m.startOf(this.timeMode).unix(),
            to: m.endOf(this.timeMode).unix(),
          },
          fields: [
            "fee_transport",
            "fee_trust",
            "fee_service",
            "profit",
            "debt",
            "volume",
            "customer_id",
            "truck_id",
            "weight",
            "count",
            "sale_user_id",
            "transaction_user_id",
            "id",
            "package_id",
            "liquidation_slip_id",
            "truck_total_weight",
            "truck_total_volume",
            "truck_total_package_count",
          ],
        },
        report: true,
      };

      if (this.currentUser.id) {
        packageOptions.auth_override = {
          id: this.currentUser.id,
          role: "kd",
          codes: this.currentUser.codes || [],
          ids: [],
        };
      } else if (userManager.checkRole(["gdkd"], true)) {
        // DEFAULT
      } else if (userManager.checkRole(["tpkd"], true)) {
        const userInfo = userManager.getUserInfo();
        const users = await apiClient.getStaffByUserAtMonth(
          userInfo.sub,
          this.month
        );
        packageOptions.auth_override = {
          id: 0,
          role: "kd",
          codes: [],
          ids: users.map((u) => u.id),
        };
      }
      let { items: packages } = await apiClient.packageGetAll(packageOptions);
      packages = packages.map((item) => {
        if (item.truck && item.truck_total_volume) {
          item.cost =
            (item.volume / item.truck_total_volume) *
            item.truck.export_fee_total;
        } else {
          item.cost = 0;
        }

        item.package_profit =
          item.fee_transport + item.fee_trust + item.profit - item.cost;
        return item;
      });

      const customerPackagesMap = util.groupBy(packages, "customer_id");

      const liquidationSlipOptions: any = {
        filters: [
          {
            key: "approve_time",
            operator: ">=",
            value: m.startOf(this.timeMode).unix(),
          },
          {
            key: "approve_time",
            operator: "<=",
            value: m.endOf(this.timeMode).unix(),
          },
        ],
        extra: {
          fields: ["surplusage"],
        },
        report: true,
      };
      if (this.currentUser.id) {
        liquidationSlipOptions.filters.push({
          key: "customer_id",
          operator: "=",
          value: this.currentUser.id,
        });
      } else if (userManager.checkRole(["gdkd"], true)) {
        // DEFAULT
      } else if (userManager.checkRole(["tpkd"], true)) {
        const userInfo = userManager.getUserInfo();
        const users = await apiClient.getStaffByUserAtMonth(
          userInfo.sub,
          this.month
        );
        liquidationSlipOptions.auth_override = {
          id: 0,
          role: "kd",
          codes: [],
          ids: users.map((u) => u.id),
        };
      }
      const { items: liquidationSlips } = await apiClient.liquidationSlipGetAll(
        liquidationSlipOptions
      );
      const customerLiquidationSlipsMap = util.groupBy(
        liquidationSlips,
        "customer_id"
      );

      let customers = this.users;
      if (this.currentUser.id) {
        customers = [this.currentUser];
      }
      for (const i in customers) {
        const customer = customers[i];
        customer.total_fee_transport = 0;
        customer.total_fee_trust = 0;
        customer.total_fee_service = 0;
        customer.total_profit = 0;
        customer.total_package_profit = 0;
        customer.total_debt = 0;
        customer.total_surplusage_current = 0;

        const customerPackages = customerPackagesMap[customer.id];
        if (customerPackages) {
          for (const pkg of customerPackages) {
            customer.total_fee_transport += pkg.fee_transport;
            customer.total_fee_trust += pkg.fee_trust;
            customer.total_fee_service += pkg.fee_service;
            customer.total_profit += pkg.profit;
            customer.total_package_profit += pkg.package_profit;
            customer.total_debt += pkg.debt;
          }
        }
        const customerLiquidationSlips =
          customerLiquidationSlipsMap[customer.id];
        if (customerLiquidationSlips) {
          for (const liquidationSlip of customerLiquidationSlips) {
            customer.total_surplusage_current += liquidationSlip.surplusage;
          }
        }
        customers[i] = customer;
      }

      this.totalItem = customers.reduce(
        (totalItem, item) => {
          totalItem.total_fee_transport += item.total_fee_transport;
          totalItem.total_fee_trust += item.total_fee_trust;
          totalItem.total_fee_service += item.total_fee_service;
          totalItem.total_profit += item.total_profit;
          totalItem.total_package_profit += item.total_package_profit;
          totalItem.total_debt += item.total_debt;
          if (item.total_surplusage) {
            totalItem.total_surplusage += item.total_surplusage;
          }
          if (item.total_surplusage_current) {
            totalItem.total_surplusage_current += item.total_surplusage_current;
          }
          if (item.total_total_debt) {
            totalItem.total_total_debt += item.total_total_debt;
          }
          return totalItem;
        },
        {
          total_fee_transport: 0,
          total_fee_trust: 0,
          total_fee_service: 0,
          total_profit: 0,
          total_package_profit: 0,
          total_debt: 0,
          total_surplusage: 0,
          total_surplusage_current: 0,
          total_total_debt: 0,
        }
      );

      this.items = customers.sort(
        (a, b) => b.total_fee_transport - a.total_fee_transport
      );
      this.loading = false;
    },
    packageMonths(packages) {
      const arr = [];
      for (const item of packages) {
        const m = moment(item.created_at);
        const year = m.year();
        if (year !== moment(this.currentDate).year()) {
          continue;
        }
        const month = m.month() + 1;
        if (!arr.includes(month)) {
          arr.push(month);
        }
      }
      return arr;
    },
    getCustomers() {
      this.loading = true;
      const allItem = {
        id: 0,
        name: "ALL",
      };
      const filters = [];
      this.users = [];
      if (this.filterSource) {
        filters.push({
          key: "source",
          operator: "=",
          value: this.filterSource,
        });
      }
      if (this.customerCode) {
        filters.push({
          key: "codes",
          operator: "like",
          value: `%${this.customerCode}%`,
        });
      }
      apiClient
        .customerGetAll({
          options: {},
          filters,
          extra: {
            load_packages: true,
          },
          report: true,
        })
        .then((res) => {
          const { items } = res;
          items.unshift(allItem);
          for (const user of items) {
            this.users.push(user);
          }
          this.currentUser = allItem;
        })
        .finally(() => {
          // this.loading = false;
        });
    },
  },
  created() {
    this.currentDate = moment().format("YYYY-MM");
    // this.currentDate = "2021-11";
    this.getCustomers();
  },
});
